import React from 'react';
import {
    Flex,
    Box
} from './shared/containers';
import { H1, H3 } from './shared/Typography';

const Hero = ({ heroIntro, heroText, heroSubtext, heroCurrent }) => {
    return (
        <Flex height='100vh' mt={{ default: '0', s: '-100vh' }} >
            <Box margin='auto' width={{ default: '80vw', s: '60vw' }} flexDirection='column' justifyContent='center'>
                <H1 color='#FFF' fontSize={{ default: '1.5em', s: '3em' }}  >{heroText}</H1>
                <H1 color='#FFF' fontSize={{ default: '1.5em', s: '3em' }}  >{heroSubtext}</H1>
                <H3 color='#FFF' fontSize={{ default: '1em', s: '1.2em' }} margin='.5em 0' >{heroCurrent}</H3>
            </Box>
        </Flex>
    )
}

export default Hero