import React from 'react';
import { Link } from 'react-router-dom';
import {
    Flex,
    ModalContainer,
    Image
} from '../components/shared/containers';
import {
    H2,
    H3,
    P,
    FactList,
    List
} from '../components/shared/Typography';
import Close from '../asset/Close.svg';

const Resume = () => {
    return (
        <ModalContainer left='0' width={{ default: '100%', m: '48em' }} padding={{ default: '1.5em', s: '3em 4em' }}>
            <Flex margin='auto' flexDirection='column' >

                <Flex justifyContent='space-between' alignItems='center'>
                    <H2 fontSize={{ default: '1em', s: '1.2em' }} color='#000'>Resume</H2>
                    <Link to="/" ><Image width='1em' src={Close} mt='2' /></Link>
                </Flex>

                <H3>Hello! I'm Joy and I create things that live somewhere on the internet</H3>

                <P>My approach to life and work is influenced by a quote from Douglas Adams’ Life, the Universe and Everything Else: “It is a mistake to think you can solve any major problems just with potatoes.”. I’m a fan of clean and simple design with high attention to detail. My passions range from cooking dishes which people rave about to their colleagues, to the falling leaves during autumn, but lie mainly in technology.</P>

                <H3>Here are a few technologies I’ve been working with recently:</H3>
                <Flex justifyContent='space-between' flexDirection={{ default: 'column', s: 'row' }} margin='-1em 0'>
                    <List width={{ default: '100%', m: '33%' }} flexDirection={{ default: 'row', s: 'column' }} flexWrap={{ default: 'wrap', s: 'no-wrap' }}>
                        <li>JavaScript [ES6+]</li>
                        <li>React</li>
                        <li>TypeScript</li>
                    </List>
                    <List width={{ default: '100%', m: '33%' }} flexDirection={{ default: 'row', s: 'column' }} flexWrap={{ default: 'wrap', s: 'no-wrap' }}>
                        <li>Cypress</li>
                        <li>Jest</li>
                        <li>Node.js</li>
                    </List>
                    <List width={{ default: '100%', m: '33%' }} flexDirection={{ default: 'row', s: 'column' }} flexWrap={{ default: 'wrap', s: 'no-wrap' }}>
                        <li>Docker</li>
                        <li>Sass</li>
                        <li>REST</li>
                    </List>
                </Flex>

                <FactList textAlign='justify'>
                    <li>
                        <P fontWeight='400'>I have been a Software Engineer at Sainsbury's since October 2021</P>
                        <P>I'm currently on the all-new Promotions team within the Price & Promotions domain, working on the all-new in-house Sainsbury's Nectar Promotions' application. If you get bonus Nectar points on your purchases, it's been applied through our application! </P>
                    </li>
                    <li>
                        <P fontWeight='400'>I was an Associate Software Engineer at Sainsbury's from March 2020 until October 2021</P>
                        <P>During my time as an associate I was part of Team Venom (Pricing), responsible for the in-house Smart Pricing Tool used to change product prices in Sainsbury's supermarkets and convenience stores across the United Kingdom. </P>
                    </li>
                    <li>
                        <P fontWeight='400'>I was a freelance Front End Developer at joys.fyi from August 2019 until March 2020</P>
                        <P>At the beginning of 2019 I decided to branch out into front end development, and switched career from design to full-time front-end development in August that same year.</P>
                    </li>
                    <li>
                        <P fontWeight='400'>I was a designer and photographer at joys.fyi from 2010 until August 2019</P>
                        <P >For the past 9 years I’ve translated my clients’ visions into effective branding, print, websites and videos using photography as a supportive medium. I started operating under my own brandname as a designer and photographer 4 years after my first freelance job for a jeweler.</P>
                    </li>
                    <li>
                        <P fontWeight='400'>I was a professional cat cuddler at Dierenopvang Breda in 2019</P>
                    </li>
                    <li>
                        <P fontWeight='400'>I was a postoffice employee at PostNL from 2016 until 2018</P>
                    </li>
                    <li>
                        <P fontWeight='400'>I was a multimedia designer at MAL Reclame in 2014</P>
                        <P >Taken on as a short term hire I was tasked to set up online and offline advertisements for existing and newly contracted clients. At this agency I had the pleasure of working on projects for Bang & Olufsen and Van Mossel Automotive.</P>
                    </li>
                    <li>
                        <P fontWeight='400'>I am a BSc in Communication & Multimedia Design and graduated from Avans University of Applied Sciences in 2014</P>
                        <P >During my bachelor's I majored in Multimedial Design and minored in Visualizing Information. At university I had the pleasure of working on real projects for companies such as Philips, Cinekid and SpinAwards.</P>
                    </li>
                    <li>
                        <P fontWeight='400'>I wrote my thesis at Insiders in 2013</P>
                        <P >My partner and I researched and developed a concept to help brands explore the world of online video and how to effectively use it. During my thesis I contributed existing projects as a photographer and videographer in the field of tourism.</P>
                    </li>
                    <li>
                        <P fontWeight='400'>I was an intern at Ensartech in 2011</P>
                        <P >During the European Project Semester I lead a multidisciplinary and multicultural team of four doing research for Ensartech, a company which had developed a new technology to process (chemical) waste without leftovers. Other than project management I was tasked with communication, designing the project files and additional research.</P>
                    </li>
                </FactList>

                <Flex justifyContent='space-between' flexDirection={{ default: 'column', s: 'row' }}>
                    <List width={{ default: '100%', m: '33%' }} flexDirection={{ default: 'row', s: 'column' }} flexWrap={{ default: 'wrap', s: 'no-wrap' }}>
                        <h3>I am</h3>
                        <li>Creative</li>
                        <li>Goal Driven</li>
                        <li>Passionate</li>
                        <li>Perfectionist</li>
                        <li>Self-motivated</li>
                    </List>
                    <List width={{ default: '100%', m: '33%' }} flexDirection={{ default: 'row', s: 'column' }} flexWrap={{ default: 'wrap', s: 'no-wrap' }}>
                        <h3>I like</h3>
                        <li>Autumn & winter</li>
                        <li>Coffee</li>
                        <li>Drawing</li>
                        <li>The sea</li>
                        <li>Skateboarding</li>
                    </List>
                    <List width={{ default: '100%', m: '33%' }} flexDirection={{ default: 'row', s: 'column' }} flexWrap={{ default: 'wrap', s: 'no-wrap' }}>
                        <h3>I love</h3>
                        <li>Coffee</li>
                        <li>Cooking</li>
                        <li>My wife-to-be</li>
                        <li>Star Wars</li>
                        <li>Traveling</li>
                    </List>
                </Flex>
            </Flex>
        </ModalContainer>
    )
}

export default Resume